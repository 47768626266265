<template>
  <el-table :data="listPackage">
    <el-table-column label="STT" type="index" width="50"></el-table-column>
    <el-table-column label="Tên" prop="name" min-width="150">
      <template slot-scope="scope">
        <span>{{ scope.row.summary }}</span>
      </template>
    </el-table-column>
    <el-table-column label="Loại" min-width="150">
      <template slot-scope="scope">
        {{ scope.row.package_type === 'tour' ? 'Gói tham quan thực tế' : 'Gói tham quan thực tế ảo VR3D' }}
      </template>
    </el-table-column>
    <el-table-column label="Giai đoạn" width="200">
      <template slot-scope="scope">
        <b>{{ scope.row.start_time }}</b> - <b>{{ scope.row.end_time }}</b>
      </template>
    </el-table-column>
    <el-table-column label="Giá vé" width="400">
      <template slot-scope="scope">
        <div v-if="scope.row.package_type === 'tour'" class="price">
          <p class="m-1"><b>Người lớn: </b>{{ scope.row.unit_prices.find((u) => u.price_type === 'adult').amount }}</p>
          <p class="m-1"><b>Trẻ em: </b>{{ scope.row.unit_prices.find((u) => u.price_type === 'infant').amount }}</p>
        </div>
        <div v-else class="price">
          <p><b>VR Tour: </b>{{ scope.row.unit_prices[0].amount }}</p>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Giảm giá" width="150">
      <template slot-scope="scope">
        {{ scope.row.discount }} (%)
      </template>
    </el-table-column>
    <el-table-column label="Thao tác" width="100" align="center" fixed="right">
      <template slot-scope="scope">
        <el-tooltip content="Chỉnh sửa" placement="top">
          <el-button icon="el-icon-edit" type="primary" circle size="small" @click="handleUpdatePackage(scope.row)"></el-button>
        </el-tooltip>
        <el-tooltip content="Xóa" placement="top">
          <el-button icon="el-icon-delete" type="danger" circle size="small" @click="handleDeletePackage(scope.row)"></el-button>
        </el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { deletePackage } from '@/services/package'
export default {
  name: 'ListPackageTable',
  props: {
    listPackage: Array
  },
  methods: {
    handleUpdatePackage(row) {
      this.$emit('updatePackage', row)
    },

    handleDeletePackage(row) {
      this.$confirm('Bạn có chắc chắn muốn xóa gói này không?', 'Cảnh báo', {
        confirmButtonText: 'Xóa',
        cancelButtonText: 'Hủy',
        type: 'warning'
      }).then(async () => {
        await deletePackage(row.id).then(() => {
          this.$notify.success({
            title: 'Thành công',
            message: 'Xóa gói thành công thành công'
          })
          this.$emit('reload')
        }).catch(() => {
          this.$notify.error({
            title: 'Thất bại',
            message: 'Xóa gói thất bại'
          })
        })
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.price {
  p {
    display: inline-block;
    margin: 5px 0;
    background-color: #e0e0e0;
    padding: 5px 10px;
  }
}
</style>
