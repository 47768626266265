import request from '@/utils/request'
import ENDPOINT from '@/config/endpoint'

export function getPackages(params) {
  return request({
    url: `${ENDPOINT.PACKAGE}`,
    method: 'get',
    params
  })
}

export function updatePackage(data) {
  return request({
    url: `${ENDPOINT.PACKAGE}`,
    method: 'patch',
    data
  })
}

export function createPackage(data) {
  return request({
    url: `${ENDPOINT.PACKAGE}`,
    method: 'post',
    data
  })
}

export function deletePackage(id) {
  return request({
    url: `${ENDPOINT.PACKAGE}?id=${id}`,
    method: 'delete'
  })
}
