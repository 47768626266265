<template>
  <div class="package-page shared-index">
    <el-row class="table-title" type="flex" align="center" justify="space-between">
      <div class="table-title__left">
        <el-tabs v-model="activeTab" type="card" @tab-click="handleReloadData">
          <el-tab-pane name="tour">
            <div slot="label">
              TOURS
              <span v-if="activeTab === 'tour'">({{ total }})</span>
            </div>
          </el-tab-pane>
          <el-tab-pane name="vr_tour">
            <div slot="label">
              TOURS 3D
              <span v-if="activeTab === 'vr_tour'">({{ total }})</span>
            </div>
          </el-tab-pane>
        </el-tabs>
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click="createVisible = true"
        >Thêm</el-button>
        <el-button
          type="primary"
          icon="el-icon-refresh-right"
          size="small"
          @click="handleReloadData"
        >Làm mới</el-button>
      </div>
      <div class="table-title__right">
        <el-input
          v-model="searchText"
          suffix-icon="el-icon-search"
          placeholder="Tìm kiếm theo tên, email"
          size="small"
          @input="handleSearch"
        ></el-input>
      </div>
    </el-row>
    <list-package
      v-loading="callingAPI"
      :list-package="listPackage"
      @updatePackage="updatePackage"
      @reload="loadData"
    ></list-package>
    <el-row type="flex" align="middle" justify="end" class="pagination">
      <el-pagination
        :current-page.sync="page"
        :page-sizes="[10, 15, 20, 30]"
        :page-size.sync="limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleChangeSize"
        @current-change="handleChangePage"
      ></el-pagination>
    </el-row>
    <package-form-popup
      :visible="createVisible"
      :package-type="activeTab"
      @close="createVisible = false"
      @reload="handleReloadData"
    />
    <update-package-popup
      :visible="updateVisible"
      :package="selectedPackage"
      @close="updateVisible = false"
      @reload="handleReloadData"
    ></update-package-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getPackages } from '@/services/package'
import ListPackage from './components/List.vue'
import PackageFormPopup from './components/popups/Form.vue'
import UpdatePackagePopup from './components/popups/Update.vue'
export default {
  name: 'Package',
  components: {
    ListPackage,
    PackageFormPopup,
    UpdatePackagePopup
  },
  data() {
    return {
      createVisible: false,
      updateVisible: false,
      callingAPI: false,
      activeTab: 'tour',
      searchText: '',
      page: 1,
      limit: 10,
      total: 10,
      listPackage: [],
      selectedPackage: {}
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  watch: {
    language: 'loadData'
  },
  mounted() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.callingAPI = true
      this.createVisible = false
      this.updateVisible = false
      const params = {
        locale: this.language,
        package_type: this.activeTab,
        page: this.page,
        search_text: this.searchText,
        per_page: this.limit
      }
      getPackages(params).then((response) => {
        this.listPackage = response.result
        this.total = response.total
        this.callingAPI = false
      }).catch(() => {
        this.callingAPI = false
      })
    },

    handleReloadData() {
      this.searchText = ''
      this.page = 1
      this.loadData()
    },

    handleChangePage(page) {
      this.page = page
      this.loadData()
    },

    handleChangeSize(size) {
      this.limit = size
      this.page = 1
      this.loadData()
    },

    handleSearch(text) {
      this.searchText = text
      this.page = 1
      this.loadData()
    },

    updatePackage(data) {
      this.selectedPackage = data
      this.updateVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

